import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import SEO from '../components/SEO'
import Content, { HTMLContent } from '../components/Content'

import Layout from '../components/Layout'

export const BlogPostTemplate = ({
  title,
  description,
  featuredimage,
  content,
  contentComponent,
}) => {
  const BlogContent = contentComponent || Content
  return (
    <section className="section">
      <SEO title={title} description={description} />

      <BackgroundImage
        className="h-64"
        fluid={featuredimage.childImageSharp.fluid}
      >
        <div className="h-full bg-darken flex items-center justify-center">
          <h1 className="text-white text-center text-5xl">{title}</h1>
        </div>
      </BackgroundImage>
      <BlogContent
        className="max-w-screen-md mx-auto px-5 mt-10"
        content={content}
      />
    </section>
  )
}

BlogPostTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  featuredimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired,
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func.isRequired,
}

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <BlogPostTemplate
        description={post.frontmatter.description}
        title={post.frontmatter.title}
        featuredimage={post.frontmatter.featuredimage}
        content={post.html}
        contentComponent={HTMLContent}
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }).isRequired,
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
